











import { MetaInfo } from 'vue-meta'
import { Component } from 'vue-property-decorator'

// components
import NewsTable from '@/components/tables/NewsTable.vue'
// mixins
import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component({
  components: {
    NewsTable,
  },
})
export default class NewsList extends PermissionsMixin {
  private metaInfo (): MetaInfo {
    return {
      title: 'Новости',
    }
  }
}
