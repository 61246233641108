import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  INewsTable,
  ManagerCoursesSelectGetRequest,
  ManagerNewsGetRequest,
  ManagerNewsIdDeleteRequest,
  ManagerNewsIdGetRequest,
  ManagerNewsIdPostRequest,
  ManagerNewsPostRequest,
  NameValueResource,
  NewsLargeResource,
  NewsListResourcePaginator,
  NewsStoreData,
} from '@/store/types'

/**
 * Работа с новостями
 */

@Module({
  dynamic: true,
  name: 'ManagerNews',
  namespaced: true,
  store,
})
class ManagerNews extends VuexModule {
  // ---------------------------- News ---------------------------- >>
  // Filter
  newsFilter: INewsTable = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
    onlyAvailable: false,
    perPage: 10,
  }

  @Mutation
  setNewsFilter(payload: any) {
    this.newsFilter = Object.assign({}, payload)
  }

  // Entities
  courses: NameValueResource[] = []
  news: NewsListResourcePaginator = GET_DEFAULT_PAGINATOR()
  currentNews: NewsLargeResource | null = null

  // Получить список курсов для селекта
  @Mutation
  setMaterialCourses(payload: NameValueResource[]) {
    this.courses = [...payload]
  }

  @Action({ rawError: true })
  async fetchCourses() {
    const { data } = await ManagerCoursesSelectGetRequest()
    this.setMaterialCourses(data)
    return data
  }
  // -- Получить список курсов для селекта --

  // Получить новости
  @Mutation
  setNews(payload: NewsListResourcePaginator) {
    this.news = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchNews() {
    const { data } = await ManagerNewsGetRequest(this.newsFilter)
    this.setNews(data)
    return data
  }
  // -- Получить новости --

  // Создать новость
  @Mutation
  setCurrentNews(payload: NewsLargeResource) {
    this.currentNews = payload
  }

  @Action({ rawError: true })
  async saveNews(payload: NewsStoreData) {
    const { data } = await ManagerNewsPostRequest(payload)
    this.setCurrentNews(data)
    return data
  }
  // -- Создать новость --

  // Получить новость
  @Action({ rawError: true })
  async fetchCurrentNews(id: number) {
    const { data } = await ManagerNewsIdGetRequest(id)
    this.setCurrentNews(data)
    return data
  }
  // -- Получить новость --

  // Редактировать новость
  @Action({ rawError: true })
  async editNews(payload: { id: number, body: NewsStoreData }) {
    const { data } = await ManagerNewsIdPostRequest(payload.id, payload.body)
    this.setCurrentNews(data)
    return data
  }
  // -- Редактировать новость --

  // Удалить новость
  @Mutation
  unsetCurrentNews() {
    this.currentNews = null
  }

  @Action({ rawError: true })
  async removeNews(id: number) {
    const { data } = await ManagerNewsIdDeleteRequest(id)
    this.unsetCurrentNews()
    return data
  }
  // -- Удалить новость --
}

const ManagerNewsModule = getModule(ManagerNews)

export default ManagerNewsModule
