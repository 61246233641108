






















































































































import { Component, Mixins, Ref } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import ManagerNewsModule from '@/store/modules/manager/news'
import { NewsListResource, Permission } from '@/store/types'
// utils
import { tableFooterOptions } from '@/utils/constants'

@Component({
  components: {
    ButtonIconAction,
    Confirmation,
    DateInput,
    Select,
    TableFooter,
    TextInput,
  },
})
export default class NewsTable extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  private footerOptions = tableFooterOptions
  private hoveredRowIndex = -1

  private get filter() {
    return ManagerNewsModule.newsFilter
  }

  private set filter(filter: any) {
    ManagerNewsModule.setNewsFilter(filter)
    this.fetchNews()
  }

  private get courses() {
    return ManagerNewsModule.courses
  }

  private get headers() {
    return [
      { cellClass: 'cell-width-8', sortable: false, text: '#', value: 'id' },
      { text: '', value: 'media' },
      { cellClass: 'cell-width-30', text: 'Название', value: 'name' },
      { cellClass: 'cell-width-25', text: 'Курсы', value: 'courses' },
      { cellClass: 'cell-width-20', text: 'Даты проведения', value: 'date' },
      { text: '', value: 'actions' },
    ]
  }

  private get pagination() {
    return ManagerNewsModule.news.meta
  }

  private get news(): NewsListResource[] {
    return ManagerNewsModule.news.data
  }

  private filterDate: string[] = this.filter.viewStartAt && this.filter.viewEndAt ? [this.filter.viewStartAt, this.filter.viewEndAt] : []

  private handleFilter(field: any, value: any) {

    if (field === 'date') {
      if (value.length) {
        this.filter.viewEndAt = value[1]
        this.filter.viewStartAt = value[0]
      } else {
        this.filter.viewEndAt = undefined
        this.filter.viewStartAt = undefined
      }
    } else {
      this.filter[field] = value
    }

    this.filter = {
      ...this.filter,
      page: 1,
    }
  }

  private handlePerPage(value: number) {
    this.filter = {
      ...this.filter,
      page: 1,
      perPage: value,
    }
  }

  private mounted() {
    this.fetchCourses()
    this.fetchNews()
  }

  private fetchCourses() {
    ManagerNewsModule.fetchCourses()
      .catch(this.notifyError)
  }

  private editNews(news: NewsListResource) {
    if (this.hasPermission(Permission.NEWS_EDIT)) {
      this.$router.push({
        name: 'manager.control.news.edit',
        params: { newsID: news.id.toString() },
      })
    } else {
      this.notifyError('У вас недостаточно прав')
    }
  }

  private confirmDeleteNews(news: NewsListResource) {
    this.confirm.open(
      'Удаление новости',
      `Вы действительно хотите удалить новость <span class="text-body-3 secondary--text">(${news.name})</span>?`,
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => {
        this.deleteNews(news)
      })
      .catch(() => {return})
  }

  private deleteNews(news: NewsListResource) {
    ManagerNewsModule.removeNews(news.id)
      .then(() => {
        if (this.news.length === 1) {
          this.filter.page = this.filter.page > 1 ? this.filter.page - 1 : 1
        } else {
          this.fetchNews()
        }
        this.notifySuccess('Новость успешно удалена.')
      })
      .catch(this.notifyError)
  }

  private handleHoverOver(id: number) {
    this.hoveredRowIndex = this.news.findIndex(_item => _item.id === id)
  }

  private handleTouch(id: number) {
    this.hoveredRowIndex = -1
    this.hoveredRowIndex = this.news.findIndex(_item => _item.id === id)
  }

  @Bind
  @Debounce(300)
  private fetchNews() {
    ManagerNewsModule.fetchNews()
      .catch(this.notifyError)
  }
}
